<template>
    <div class="flex flex-col items-center justify-center">
        <div class="items-center flex-col text-center mt-12">
            <h1 class="text-[12rem] font-heading font-bold">
                404
            </h1>
            <h2 class="text-primary text-3xl">
                Page not found
            </h2>
        </div>
    </div>
</template>
